import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useJobContext, useNotificationContext} from 'contexts';

import {PartialJob} from 'shared/types/PartialJob';
import CBCDialog from 'shared/components/ConfirmationDialog/CBCDialog';
import Footer, {
    CloseEventType,
} from 'shared/components/ConfirmationDialog/Footer';
import JobPendingVariation from 'components/customer/JobDashboard/JobPendingVariation';
import {useRemoveProductVariationsMutation} from 'components/customer/Product/store/productApi';
import {genericMessageHandler} from 'shared/helpers';
import {useFetchBenchesData} from 'components/customer/BTM/helper/useFetchBenchesData';
import {
    useConfirmVariationsMutation,
    useGetJobCostQuery,
    useJobResetMutation,
    useLazyGetExtraVariationsQuery,
    useRemoveExtraVariationMutation,
} from 'components/customer/Job/store/jobApi';
import {useConfirmationDialog} from 'shared';
export interface VariationStateProps {
    removedBenchIds: number[];
    setRemovedBenchIds: React.Dispatch<React.SetStateAction<number[]>>;
    removedExtraVariationIds: number[];
    setRemovedExtraVariationIds: React.Dispatch<React.SetStateAction<number[]>>;
    removedJobCabinetIds: number[];
    setRemovedJobCabinetIds: React.Dispatch<React.SetStateAction<number[]>>;
    totalVariationCost: number;
    setTotalVariationCost: React.Dispatch<React.SetStateAction<number>>;
}

const useVariationState = (): VariationStateProps => {
    const [removedBenchIds, setRemovedBenchIds] = useState<number[]>([]);
    const [removedExtraVariationIds, setRemovedExtraVariationIds] = useState<
        number[]
    >([]);
    const [removedJobCabinetIds, setRemovedJobCabinetIds] = useState<number[]>(
        []
    );
    const [totalVariationCost, setTotalVariationCost] = useState<number>(0);

    return {
        removedBenchIds,
        setRemovedBenchIds,
        removedJobCabinetIds,
        setRemovedJobCabinetIds,
        totalVariationCost,
        setTotalVariationCost,
        setRemovedExtraVariationIds,
        removedExtraVariationIds,
    };
};

export const JobVariations = () => {
    const {job, refresh, expiredJobPrice} = useJobContext() as PartialJob;
    const [removeProductVariations] = useRemoveProductVariationsMutation();
    const [jobReset] = useJobResetMutation();
    const [confirmVariations] = useConfirmVariationsMutation();
    const {notify} = useNotificationContext();
    const [displayDialog, setDisplayDialog] = useState<boolean>(true);
    const benches = useFetchBenchesData();
    const [getExtraVariations, {data: extraVariationList}] =
        useLazyGetExtraVariationsQuery();
    const [removeExtraVariation] = useRemoveExtraVariationMutation();
    useEffect(() => {
        if (job && job.id) {
            void getExtraVariations({jobId: job.id}, true);
        }
    }, [job]);
    const variationStateProps = useVariationState();
    const {showDialog, dialog} = useConfirmationDialog();
    const {data: cost} = useGetJobCostQuery(
        {jobId: job.id},
        {
            skip: typeof job?.id === 'undefined',
        }
    );

    const isAllVariationsRemoved = useMemo(() => {
        const removedJobCabinetIds = variationStateProps.removedJobCabinetIds;
        const removedBenchIds = variationStateProps.removedBenchIds;
        const removedExtraVariationIds =
            variationStateProps.removedExtraVariationIds;

        const allCabinetIds = (job?.rooms || []).flatMap((room) =>
            (room.jobCabinets || [])
                .filter((item) => item.comment)
                .map((item) => item.id)
        );

        const allBenchIds = benches
            ?.filter((bench) => bench.comment && !bench.isOld)
            .map((bench) => bench.id);

        const allRemovedCabinetsIdsAvailable =
            allCabinetIds.length === 0 ||
            (removedJobCabinetIds.length > 0 &&
                allCabinetIds.every((id) => removedJobCabinetIds.includes(id)));

        const allRemovedBenchIdsAvailable =
            allBenchIds.length === 0 ||
            (removedBenchIds.length > 0 &&
                allBenchIds.every((id) => removedBenchIds.includes(id)));

        const allRemovedExtraIdsAvailable =
            extraVariationList?.length === 0 ||
            (removedExtraVariationIds.length > 0 &&
                extraVariationList.every((extra) =>
                    removedExtraVariationIds.includes(extra.id)
                ));

        return (
            allRemovedBenchIdsAvailable &&
            allRemovedCabinetsIdsAvailable &&
            allRemovedExtraIdsAvailable
        );
    }, [
        job,
        benches,
        extraVariationList,
        variationStateProps.removedJobCabinetIds,
        variationStateProps.removedBenchIds,
        variationStateProps.removedExtraVariationIds,
    ]);

    const displayDialogModal = useCallback(() => {
        const isAccepted = job?.accepted;
        const hasComment =
            job?.rooms?.some((room) =>
                room.jobCabinets?.some((item) => item.comment)
            ) ||
            benches?.some((bench) => !bench.isOld && bench.comment) ||
            extraVariationList?.some((extra) => extra.name);

        const acceptedVariations = job?.variationsAllAccepted;

        if (!displayDialog) {
            return false;
        }

        if (
            isAccepted &&
            !acceptedVariations &&
            !expiredJobPrice &&
            (cost?.freightCost > 0 || hasComment)
        ) {
            return true;
        }

        return false;
    }, [
        displayDialog,
        job,
        benches,
        extraVariationList,
        expiredJobPrice,
        cost?.freightCost,
    ]);

    const toggle = useCallback(
        async (event: CloseEventType) => {
            // confirm variations
            if (event == CloseEventType.APPLY) {
                setDisplayDialog(!displayDialog);

                try {
                    await removeProductVariations({
                        data: {
                            jobCabinetIds:
                                variationStateProps.removedJobCabinetIds,
                            benchIds: variationStateProps.removedBenchIds,
                        },
                    }).unwrap();

                    if (variationStateProps.removedExtraVariationIds.length) {
                        await removeExtraVariation({
                            data: {
                                ids: variationStateProps.removedExtraVariationIds,
                            },
                        }).unwrap();
                    }

                    await confirmVariations({jobId: job?.id});
                    refresh({reloadFromServer: true});

                    genericMessageHandler(
                        notify,
                        {
                            message:
                                'Variations have been successfully accepted.',
                        },
                        'success'
                    );
                } catch (error) {
                    genericMessageHandler(notify, {
                        message: 'Something went wrong',
                    });
                    throw error;
                }
            }
            // remove all variations
            if (event == CloseEventType.CANCEL) {
                showDialog({
                    title: 'Delete All Variations',
                    message: `Are you sure you want to remove all variations associated with job ${job.displayId}?`,
                    yes: async () => {
                        setDisplayDialog(!displayDialog);
                        const allRowsIds =
                            job?.rooms?.flatMap((room) =>
                                room.jobCabinets
                                    ?.filter(
                                        (item) =>
                                            item.variationCost > 0 ||
                                            item.comment
                                    )
                                    .map((item) => item.id)
                            ) || [];

                        const allBenchIds = benches
                            .filter(
                                (bench) =>
                                    !bench.isOld &&
                                    (bench.variationCost > 0 || bench.comment)
                            )
                            .map((bench) => bench.id);

                        const allExtraVariations = extraVariationList.map(
                            (extra) => extra.id
                        );

                        try {
                            await removeProductVariations({
                                data: {
                                    jobCabinetIds: allRowsIds,
                                    benchIds: allBenchIds,
                                },
                            }).unwrap();

                            if (allExtraVariations.length) {
                                await removeExtraVariation({
                                    data: {
                                        ids: allExtraVariations,
                                    },
                                }).unwrap();
                            }

                            refresh({reloadFromServer: true});
                            genericMessageHandler(
                                notify,
                                {
                                    message:
                                        'Variations have been successfully removed.',
                                },
                                'success'
                            );
                        } catch (error) {
                            genericMessageHandler(notify, {
                                message: 'Something went wrong',
                            });
                            throw error;
                        }
                    },
                });
            }
            // edit
            if (event == CloseEventType.OK) {
                setDisplayDialog(!displayDialog);

                try {
                    await jobReset({jobId: job?.id, isReset: false}).unwrap();
                    refresh({reloadFromServer: true});

                    genericMessageHandler(
                        notify,
                        {message: 'Job has been reactivated'},
                        'success'
                    );
                } catch (error) {
                    genericMessageHandler(notify, {
                        message: 'Something went wrong',
                    });
                    throw error;
                }
            }
        },
        [
            job,
            expiredJobPrice,
            displayDialog,
            benches,
            extraVariationList,
            variationStateProps.removedBenchIds,
            variationStateProps.removedJobCabinetIds,
            variationStateProps.removedExtraVariationIds,
        ]
    );

    return (
        <CBCDialog
            zindex={1040}
            display={displayDialogModal()}
            title="Variation Request"
            footer={
                <Footer
                    okIcon="Button-Edit.svg"
                    yesButtonText="Confirm Variations"
                    okButtonText="Edit Job"
                    noButtonText={
                        !isAllVariationsRemoved ? `Delete All Variations` : ''
                    }
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClose={toggle}
                />
            }>
            {dialog}
            <JobPendingVariation {...variationStateProps} />
        </CBCDialog>
    );
};
