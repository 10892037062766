// @flow
import React, {useMemo, useRef, useCallback} from 'react';

import {useDashboardContext, useAppContext} from 'contexts';
import {isEmpty} from 'lodash';
import {jobId, CBCButton} from 'shared/helpers';
import {useNavigate} from 'react-router-dom';

export const JOB_STATUS = {
    PROCESSING: 0,
    PENDING_APPROVAL: 1,
    PENDING_PAYMENT: 2,
    PRODUCTION: 3,
    JOB_COMPLETED: 4,
    JOB_PENDING_CONFIRMATION: 5,
    CUSTOMER_APPROVED: 7,
};

const JOB_PDF_OPTIONS = {
    TABLE_PDF: 2,
    ORDER_PDF: 3,
};

export const LastJobButton = (): React$Element<any> => {
    const {lastJob} = useDashboardContext();
    const navigate = useNavigate();
    const {userProfile} = useAppContext();

    const goToJob = useRef((job) => {
        navigate(`/v2/job/${job}/dashboard`);
    }).current;

    const jobPDF = useCallback(
        (job) => {
            let pdfName;
            let pdfFile;

            switch (userProfile.defaultJobPropertiesPdf) {
                case JOB_PDF_OPTIONS.ORDER_PDF:
                    pdfName = 'Quote PDF';
                    pdfFile = 'quotePdf.php';
                    break;
                case JOB_PDF_OPTIONS.TABLE_PDF:
                default:
                    pdfName = 'Job Details PDF';
                    pdfFile = 'jobPropertiesPDFTable.php';
                    break;
            }

            return [
                `View ${pdfName}`,
                () => window.open(`/${pdfFile}?jobId=${job}`, 'blank_'),
            ];
        },
        [userProfile]
    );

    const jobInvoicePDF = useRef((job) => {
        window.open(`/jobPDF.php?jobId=${job}`, 'blank_');
    }).current;

    const [buttonText, eventHandler] = useMemo<[string, () => void]>(() => {
        switch (lastJob.status) {
            case JOB_STATUS.JOB_PENDING_CONFIRMATION:

                    return [
                        'Confirm Costs',
                        () => goToJob(jobId(lastJob.id)),
                    ];


            case JOB_STATUS.PENDING_APPROVAL:
                return jobPDF(lastJob.id);
            case JOB_STATUS.CUSTOMER_APPROVED:
                return jobPDF(lastJob.id);

            case JOB_STATUS.PENDING_PAYMENT:
                if (userProfile.allowCustomerInvoicingPdf == 1) {
                    return [
                        'View invoice PDF',
                        () => jobInvoicePDF(lastJob.id),
                    ];
                }
                return jobPDF(lastJob.id);

            case JOB_STATUS.PRODUCTION:
            case JOB_STATUS.JOB_COMPLETED:
                if (
                    lastJob.datePaid.indexOf('-') === 0 &&
                    userProfile.allowCustomerInvoicingPdf == 1
                ) {
                    return [
                        'View invoice PDF',
                        () => jobInvoicePDF(lastJob.id),
                    ];
                }

                return jobPDF(lastJob.id);

            default:
                return [
                    'Continue Editing Job',
                    () => goToJob(jobId(lastJob.id)),
                ];
        }
    }, [lastJob, userProfile]);

    if (isEmpty(lastJob)) {
        return <></>;
    }

    return (
        <div>
            <CBCButton
                id="update-last-job-button"
                iconName="Button-Edit.svg"
                disabled={userProfile.inActiveManufacturer}
                onClick={eventHandler}>
                {buttonText}
            </CBCButton>
            <strong>Last Job: #{jobId(lastJob.id)}</strong>
        </div>
    );
};
