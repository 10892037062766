import React, {useMemo} from 'react';
import {useDeleteBenchMutation} from 'components/customer/BTM/store/btmApi';
import {useAppContext, useJobContext, useNotificationContext} from 'contexts';
import {useNavigate} from 'shared/reload/helper/useNavigate';
import {useConfirmationDialog} from 'shared';
import {genericMessageHandler} from 'shared/helpers';
import {useGetBenchId} from 'components/customer/BTM/helper/useGetBenchId';
import {invalidateJobCost} from 'components/customer/Job/store/jobApi';
import {useAppDispatch} from 'store/customer';
import {clearAll} from 'components/customer/BTM/store/btmSlice';
import {
    useFetchBenchesData,
    BenchTableInterface as Bench,
} from 'components/customer/BTM/helper/useFetchBenchesData';
import {deleteBenchtop} from 'service';
import {PartialJob} from 'shared/types/PartialJob';

export const useBenchtopTableConfig = () => {
    const dispatch = useAppDispatch();
    const {benchId} = useGetBenchId();
    const {notify} = useNotificationContext();
    const {userProfile} = useAppContext();

    const {job, room, isPageDirty, actionButtonsEnabled, setIsPageDirty} =
        useJobContext() as PartialJob;
    const {showDialog, dialog} = useConfirmationDialog();
    const navigate = useNavigate();
    const benches = useFetchBenchesData();

    const [deleteBench] = useDeleteBenchMutation();

    const addBenchtopUrl = `/v2/job/${job.displayId}/benchtop-module`;

    const removeBenchtop = (
        bench: Bench,
        event: React.MouseEvent<HTMLElement>
    ) => {
        const button = event.target.parentElement as HTMLButtonElement;
        showDialog({
            title: 'Delete benchtop',
            message:
                'Are you sure you want to delete this benchtop from the job?',
            yes: async () => {
                try {
                    button.disabled = true;
                    if (!bench.isOld) {
                        await deleteBench({benchId: bench.id});
                    } else {
                        await deleteBenchtop(
                            job.id,
                            room.id,
                            bench.externalBenchId
                        );
                        setIsPageDirty([]);
                        // NOTE: only invalidate if it is old benchtops
                        dispatch(invalidateJobCost());
                    }

                    button.disabled = false;
                    genericMessageHandler(
                        notify,
                        {message: 'Benchtop removed from the job'},
                        'success'
                    );

                    if (benchId == bench.id) {
                        navigate(`/v2/job/${job.displayId}/dashboard`);
                    }
                } catch (e) {
                    button.disabled = false;
                    genericMessageHandler(notify, e);
                }
            },
        });
    };

    const editBenchtop = (row: Bench) => {
        if (row.isOld) {
            navigate(
                `/v2/job/${job.displayId}/room/${room.id}/benchtop-designer?benchType=${row.btdShape}&benchId=${row.externalBenchId}`
            );
        } else {
            dispatch(clearAll());
            navigate(
                `/v2/job/${job.displayId}/benchtop-module/type/${row.shape.type}/materialType/${row.type.id}/material?bench=${row.id}`
            );
        }
    };

    const strongFormatter = (value: string, row: Bench) => {
        if (row.id == Number(benchId)) {
            return <strong>{value}</strong>;
        } else {
            return value;
        }
    };

    const noRoomAndVariationBenchtop = (variations: number) => {
        if (variations > 0) {
            return <>No Benchtops.</>;
        } else {
            return (
                <>
                    No Benchtops.{' '}
                    {job.displayId && (
                        <span
                            style={{color: 'red', cursor: 'pointer'}}
                            onClick={() => navigate(addBenchtopUrl)}>
                            <strong>Click Add BenchTops</strong> to add one.
                        </span>
                    )}
                </>
            );
        }
    };

    const tableConfig = useMemo(() => {
        const tableConfig = {
            fields: [
                {
                    fieldName: 'jobBenchtopNumber',
                    title: '#',
                    format: strongFormatter,
                },
                {
                    fieldName: 'name',
                    title: 'Current Benchtops',
                    format: strongFormatter,
                },
                {
                    fieldName: 'dimension',
                    title: 'Dimensions',
                    width: 80,
                    format: strongFormatter,
                },
                userProfile.show_pricing && {
                    fieldName: 'cost',
                    title: 'Cost',
                    width: 65,
                    format: strongFormatter,
                },
            ],
            data: benches,
            noRecordsText: (
                <>{noRoomAndVariationBenchtop(job.variationsConfirmed)}</>
            ),
            options: {},
        };

        if (job.status != 5) {
            tableConfig.options = {
                title: '',
                width: 75,
                buttons: [
                    {
                        iconName: 'Options-Edit.svg',
                        title: 'Edit Benchtop',
                        action: editBenchtop,
                        disabled: (row: Bench) => row.id == benchId,
                    },
                    {
                        iconName: 'Options-Delete.svg',
                        title: 'Delete Benchtop',
                        action: removeBenchtop,
                        confirmChanges: isPageDirty.length > 0,
                    },
                ],
            };
        }

        if (!actionButtonsEnabled) {
            tableConfig.options = undefined;
        }

        return tableConfig;
    }, [
        benchId,
        job,
        room,
        benches,
        isPageDirty,
        actionButtonsEnabled,
        navigate,
    ]);

    return {
        tableConfig,
        dialog,
    };
};
